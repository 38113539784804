var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ck-character-bar-container",
      attrs: { title: _vm.count + " / " + _vm.maxLength }
    },
    [
      _c("div", {
        staticClass: "ck-character-bar",
        style: { backgroundColor: _vm.barColour, width: _vm.barWidthStyle }
      }),
      _vm.barWidth >= 100
        ? _c("gov-error-message", {
            attrs: { for: "chracter-count" },
            domProps: { textContent: _vm._s("Character count exceeded") }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }